import Vue from 'vue';
import { VDataTableHeader } from 'vuetify/lib';
import VDataTableHeaderMobile from 'vuetify/lib/components/VDataTable/VDataTableHeaderMobile';
import mixins from 'vuetify/lib/util/mixins';
import header from 'vuetify/lib/components/VDataTable/mixins/header';
import { wrapInArray, convertToUnit } from 'vuetify/lib/util/helpers';

import dedupeModelListeners from 'vuetify/lib/util/dedupeModelListeners';
import mergeData from 'vuetify/lib/util/mergeData';
import rebuildSlots from 'vuetify/lib/util/rebuildFunctionalSlots'; // Types

import DataTableHeaderDesktopWithTitle from './DataTableHeaderDesktopWithTitle';

export default VDataTableHeader.extend({
  name: 'DataTableHeaderWithTitle',

  render(h, { props, data, slots }) {
    dedupeModelListeners(data);
    const children = rebuildSlots(slots(), h);
    data = mergeData(data, {
      props,
    });

    if (props.mobile) {
      return h(VDataTableHeaderMobile, data, children);
    } else {
      return h(DataTableHeaderDesktopWithTitle, data, children);
    }
  },
});

import VDataTableHeaderDesktop from 'vuetify/lib/components/VDataTable/VDataTableHeaderDesktop';
import { wrapInArray, convertToUnit } from 'vuetify/lib/util/helpers';
import ThExt from '@/components/ui/tables/ThExt.vue';

export default VDataTableHeaderDesktop.extend({
  name: 'DataTableHeaderDesktopWithTitle',

  components: {
    ThExt,
  },

  methods: {
    /**
     * Most logic taken from parent class
     */
    genHeader(header) {
      const data = {
        attrs: {
          role: 'columnheader',
          scope: 'col',
          'aria-label': header.text || '',
        },
        style: {
          width: convertToUnit(header.width),
          minWidth: convertToUnit(header.width),
        },
        class: [
          `text-${header.align || 'start'}`,
          ...wrapInArray(header.class),
          header.divider && 'v-data-table__divider',
        ],
        on: {},
      };
      const children = [];

      if (header.value === 'data-table-select' && !this.singleSelect) {
        return this.$createElement('th', data, [this.genSelectAll()]);
      }

      children.push(
        this.$scopedSlots[header.value]
          ? this.$scopedSlots[header.value]({
              header,
            })
          : header?.description
          ? this.$createElement('dfn', {
              attrs: {
                title: header.description,
              },
              // DOM properties
              domProps: {
                innerHTML: header.text,
              },
            })
          : this.$createElement('span', [header.text]),
      );

      if (!this.disableSort && (header.sortable || !header.hasOwnProperty('sortable'))) {
        data.on.click = () => this.$emit('sort', header.value);

        const sortIndex = this.options.sortBy.findIndex((k) => k === header.value);
        const beingSorted = sortIndex >= 0;
        const isDesc = this.options.sortDesc[sortIndex];
        data.class.push('sortable');
        const { ariaLabel, ariaSort } = this.getAria(beingSorted, isDesc);
        data.attrs['aria-label'] += `${header.text ? ': ' : ''}${ariaLabel}`;
        data.attrs['aria-sort'] = ariaSort;

        if (beingSorted) {
          data.class.push('active');
          data.class.push(isDesc ? 'desc' : 'asc');
        }

        if (header.align === 'end') children.unshift(this.genSortIcon());
        else children.push(this.genSortIcon());

        if (this.options.multiSort && beingSorted) {
          children.push(
            this.$createElement(
              'span',
              {
                class: 'v-data-table-header__sort-badge',
              },
              [String(sortIndex + 1)],
            ),
          );
        }
      }

      if (this.showGroupBy && header.groupable !== false) children.push(this.genGroupByToggle(header));
      return this.$createElement('th', data, children);
    },
  },
});
